<template>
  <ModuleForm
    :module-name="$t('moduleForm.quote')"
    :module="quote"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        :site-id-for-permission-check="quote.site"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        :site-id-for-permission-check="quote.site"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="quote.id"
        :record-id="quote.id"
        :required-permissions="requiredPermissions.deleteButton"
        :site-id-for-permission-check="quote.site"
        store-name="quote"
        list-router-path="quote"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/quote')"
      />
    </template>
    <template #left-column>
      <Input
        v-model="quote.title"
        @blur="$v.quote.title.$touch()"
        :error="$v.quote.title.$error"
        id="quote_title"
        :label="$t('quote.title')"
        :placeholder="$t('quote.title_placeholder')"
        :disabled="disabled"
      />
      <Select
        v-model="quote.site"
        @blur="$v.quote.site.$touch()"
        :error="$v.quote.site.$error"
        :options="sites"
        id="quote_site"
        :label="$t('quote.site')"
        :disabled="disabled"
      />
      <Input
        v-model="quote.author"
        id="quote_author"
        :label="$t('quote.author')"
        :disabled="disabled"
      />
      <Datepicker
        v-model="quote.publishedSince"
        id="quote_publishedSince"
        :label="$t('quote.published_since')"
        :placeholder="$t('quote.published_since_placeholder')"
        :show-set-now="!disabled"
        :disabled="disabled"
      />
      <!-- hide till CMS-541 is resolved -->
      <div v-if="false" class="published-dates">
        <Datepicker
          class="published-dates__datepicker"
          v-model="quote.publishedUntil"
          id="quote_publishedUntil"
          :label="$t('quote.published_until')"
          :placeholder="$t('quote.published_until_placeholder')"
          :disabled="disabled"
        />
      </div>
      <div class="title-text">
        {{ $t('quote.bodytext') }}
      </div>
      <RichTextEditor
        v-model="quote.bodyText"
        :other-options="richTextEditorConfig"
        id="bodyText"
        rows="10"
        :readonly="disabled"
      />
    </template>
    <template #right-column>
      <div class="title-text">
        {{ $t('quote.image') }}
      </div>
      <ModuleFormPhotoBox
        :image="image"
        :disabled="disabled"
        :site="quote.site"
        :media-usage-type="mediaUsageType"
        @set-media="setImage"
        @remove-media="removeImage"
      />
      <!-- hide till CMS-541 is resolved -->
      <OutlinedCheckbox
        v-if="false"
        v-model="quote.unpublished"
        id="quote_setUnpublished"
        :label="$t('media.set_unpublished')"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import QuoteModel from '@/model/Quote'
import NotifyService from '@/services/NotifyService'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import RichTextEditor from '@/components/form/RichTextEditor'
import PermissionService from '@/services/PermissionService'
import { MEDIA_USAGE_TYPE_QUOTE } from '@/model/ValueObject/MediaUsageTypes'
import { MODULE_MODULES_QUOTE } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'QuoteNewView',
  data () {
    return {
      quote: this._.cloneDeep(QuoteModel),
      richTextEditorConfig: TinyMceConfig.getConfig(),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.QUOTE_PERMISSIONS,
      mediaUsageType: MEDIA_USAGE_TYPE_QUOTE
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image () {
      return this.$store.getters['quote/image']
    },
    sites () {
      return this.$store.getters['site/enabledSitesForForm'](MODULE_MODULES_QUOTE, this.quote, this.disabled)
    }
  },
  validations: {
    quote: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      site: {
        required,
        minValue: minValue(1)
      }
    }
  },
  components: {
    RichTextEditor,
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    ModuleForm,
    OutlinedCheckbox,
    Input,
    Select,
    Datepicker
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.quote.image = null
        if (this.$store.getters['quote/image']) {
          this.quote.image = this.$store.getters['quote/image'].id
        }
        this.$store.dispatch('quote/create', this.quote)
          .then(() => {
            if (this.$store.getters['quote/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.quote = this.$store.getters['quote/detail']
              this.goToEdit()
            } else {
              NotifyService.setErrorMessage(this.$store.getters['quote/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('quote/storeImage', media)
      })
    },
    removeImage () {
      this.$store.commit('quote/storeImage', null)
    },
    goToEdit () {
      this.$router.push('/quote/' + this.quote.id + '/edit')
    }
  },
  created () {
    this.$store.commit('quote/storeImage', null)
  }
}
</script>
<style scoped lang="scss">
.published-dates {
  display: flex;
  gap: 2%;
  &__datepicker {
    flex: 0 0 49%;
  }
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
</style>
